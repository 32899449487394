.Footer-cont{
    position: relative;
}
.Footer-cont>hr{
    border: 1px solid var(--lightgray);
}

 .footer{
    padding: 1rem 2rem ;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 8rem;
}

.social-links{
    display: flex;
    gap: 4rem;
}
.social-links img {
    margin-top: 0rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
